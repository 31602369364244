import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-grand-rapids-michigan.png'
import image0 from "../../images/cities/scale-model-of-escape-michigan---escape-rooms-in-grand-rapids-michigan.png"
import image1 from "../../images/cities/scale-model-of-locked-up---grand-rapids-|-escape-room-experience-in-grand-rapids-michigan.png"
import image2 from "../../images/cities/scale-model-of-calder-plaza-in-grand-rapids-michigan.png"
import image3 from "../../images/cities/scale-model-of-grand-rapids-children's-museum-in-grand-rapids-michigan.png"
import image4 from "../../images/cities/scale-model-of-frederik-meijer-gardens-in-grand-rapids-michigan.png"
import image5 from "../../images/cities/scale-model-of-downtown-grand-rapids-inc.-in-grand-rapids-michigan.png"
import image6 from "../../images/cities/scale-model-of-grand-rapids-art-museum-in-grand-rapids-michigan.png"
import image7 from "../../images/cities/scale-model-of-urban-institute-for-contemporary-arts-(uica)-in-grand-rapids-michigan.png"
import image8 from "../../images/cities/scale-model-of-gosite-in-grand-rapids-michigan.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Grand Rapids'
            state='Michigan'
            image={image}
            lat='42.9612'
            lon='-85.6556'
            attractions={ [{"name": "Escape Michigan - Escape Rooms", "vicinity": "3641 Plainfield Ave NE, Grand Rapids", "types": ["point_of_interest", "establishment"], "lat": 43.02945589999999, "lng": -85.63101369999998}, {"name": "Locked Up - Grand Rapids | Escape Room Experience", "vicinity": "3323 28th St SE, Kentwood", "types": ["point_of_interest", "establishment"], "lat": 42.914485, "lng": -85.58608000000004}, {"name": "Calder Plaza", "vicinity": "300 Monroe Ave NW, Grand Rapids", "types": ["point_of_interest", "establishment"], "lat": 42.9689388, "lng": -85.670617}, {"name": "Grand Rapids Children's Museum", "vicinity": "11 Sheldon Ave NE, Grand Rapids", "types": ["museum", "point_of_interest", "establishment"], "lat": 42.9636004, "lng": -85.667191}, {"name": "Frederik Meijer Gardens", "vicinity": "1000 E Beltline Ave NE, Grand Rapids", "types": ["art_gallery", "museum", "park", "point_of_interest", "establishment"], "lat": 42.9795015, "lng": -85.58564920000003}, {"name": "Downtown Grand Rapids Inc.", "vicinity": "29 Pearl St NW #1, Grand Rapids", "types": ["point_of_interest", "establishment"], "lat": 42.9664797, "lng": -85.66947299999998}, {"name": "Grand Rapids Art Museum", "vicinity": "101 Monroe Center St NW, Grand Rapids", "types": ["museum", "point_of_interest", "establishment"], "lat": 42.965006, "lng": -85.6710511}, {"name": "Urban Institute for Contemporary Arts (UICA)", "vicinity": "2 Fulton St W, Grand Rapids", "types": ["art_gallery", "museum", "movie_theater", "point_of_interest", "establishment"], "lat": 42.9630732, "lng": -85.668138}, {"name": "gosite", "vicinity": "101 Monroe Center St NW, Grand Rapids", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 42.965309, "lng": -85.67111399999999}] }
            attractionImages={ {"Escape Michigan - Escape Rooms":image0,"Locked Up - Grand Rapids | Escape Room Experience":image1,"Calder Plaza":image2,"Grand Rapids Children's Museum":image3,"Frederik Meijer Gardens":image4,"Downtown Grand Rapids Inc.":image5,"Grand Rapids Art Museum":image6,"Urban Institute for Contemporary Arts (UICA)":image7,"gosite":image8,} }
       />);
  }
}